import request from "@/utils/request";

/**
 * 我的收藏列表
 * @param {*} data 
 * @param {String} page || 页码
 * @param {String} pageSize || 页码大小
 * @param {String} sortProperty || 排序
 * @param {String} sortDirection || ASC(升序)=0 DESC(降序)=1
 * @param {String} type || Tag(话题)=1  Category(分类)=2  Article(文章)=4
 * @returns 
 */
export const getCollectLists = (data) => {
  return request({
    url: "/api/app/Collect/myQuery",
    method: "post",
    data: data,
  });
};

/**
 * 添加收藏
 * @param { Array } ids || id组
 * @param { String } type || Tag(话题)=1  Category(分类)=2  Article(文章)=4
 */
export const addCollect = (data) => {
  return request({
    url: "/api/app/Collect/add",
    method: "post",
    data: data,
  });
};

/**
 * 取消收藏
 * @param { Array } id || id
 * @param { String } type || Tag(话题)=1  Category(分类)=2  Article(文章)=4
 */
export const cancelCollect = (data) => {
  return request({
    url: "/api/app/Collect/cancel",
    method: "put",
    data: data,
  });
};
